<script>
import LocationSelect from '@/views/components/LocationSelect.vue'
import store from '@/store'
import userApi from '@/axios/api/user'
import messages from '@/libs/messages/toast'
import { BSpinner } from 'bootstrap-vue'
import CoverageAreas from '@/views/components/CoverageAreas.vue'
import toast from '@/libs/toast'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    LocationSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    CoverageAreas,
  },

  data() {
    return {
      formSubmitted: false,

      formData: {
        name: null,
        phone1: null,
        phone2: null,
        areas: [],
        location: null,
      },

      required,
      email,
      mapLocationError: null,
      coverageError: null,
    }
  },

  computed: {
    user() {
      return store.getters['user/user']
    },
  },

  mounted() {
    localize('ar')
  },

  methods: {
    locationChanged(location) {
      this.formData.latLng = location.latLng
      this.formData.fullAddress = location.fullAddress
      this.mapLocationError = null
    },

    coverageChanged(coverage) {
      coverage.forEach(area => {
        this.formData.areas.push(area.id)
      })

      this.coverageError = null
    },

    add() {
      this.mapLocationError = null
      this.formSubmitted = true
      this.$refs.addNewbrachform.validate().then(success => {
        if (!success) {
          this.formSubmitted = false
          return
        }

        if (this.formData.latLng == null || this.formData.fullAddress == null) {
          this.formSubmitted = false
          this.mapLocationError = 'العنوان على الخريطة مطلوب'
          return
        }

        if (this.formData.areas.length === 0) {
          this.formSubmitted = false
          this.coverageError = true
          return
        }

        const formData = {
          name: this.formData.name,
          areas: this.formData.areas,
          latLng: this.formData.latLng,
          fullAddress: this.formData.fullAddress,
          phone1: this.formData.phone1,
          phone2: this.formData.phone2,
        }

        userApi
          .addBranch(formData)
          .then(response => {
            store.commit('user/addBranch', response.data.data)

            this.$router.replace({ name: 'account-settings' }).then(() => {
              toast.success(this.$bvToast, messages.success, messages.branchAdded)
            })
          })
          .catch(error => {
            this.formSubmitted = false
            console.error(error)
            toast.error(this.$bvToast, messages.error, messages.errorDetails)
          })
      })
    },
  },
}
</script>

<template>
  <div class="card border-0 rounded shadow">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <validation-observer ref="addNewbrachform">
            <form
              ref="addBranchForm"
              class="mt-3"
            >
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group position-relative">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      vid="name"
                      rules="required"
                    >
                      <label>الإسم</label>
                      <span class="text-danger">*</span>
                      <input
                        v-model="formData.name"
                        name="name"
                        type="text"
                        class="form-control"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group position-relative">
                    <validation-provider
                      #default="{ errors }"
                      name="phone1"
                      vid="phone1"
                      rules="required"
                    >
                      <label>رقم التليفون 1</label>
                      <span class="text-danger">*</span>
                      <input
                        v-model="formData.phone1"
                        name="phone1"
                        type="text"
                        class="form-control"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group position-relative">
                    <label>رقم التليفون 2</label>
                    <input
                      v-model="formData.phone2"
                      name="phone2"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 border-top border-bottom py-2 my-2">
                  <label class="font-weight-bold py-1"> نطاق التغطية </label>
                  <span class="text-danger">*</span>
                  <CoverageAreas @coverageChanged="coverageChanged" />
                  <small
                    v-if="coverageError !== null"
                    class="text-danger"
                  >لابد من تحديد نطاق التغطية</small>
                </div>
              </div>

              <LocationSelect
                :user="user"
                class="row"
                @locationChanged="locationChanged"
              />
              <small
                v-if="mapLocationError !== null"
                class="text-danger"
              >{{
                mapLocationError
              }}</small>

              <!--end row-->
              <div class="row">
                <div class="col-sm-12">
                  <button
                    id="submit"
                    type="submit"
                    name="send"
                    class="btn btn-primary"
                    value="حفظ"
                    :disabled="formSubmitted"
                    @click.prevent="add"
                  >
                    <BSpinner
                      v-if="formSubmitted"
                      small
                      class="mx-1"
                    />
                    حفظ
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
